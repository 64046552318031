import * as moment from "moment";

export class DateWiseResponseStats {
    responseDate:  string = "";
    responseCount: number =0;
    ohbiScore: string = "";


  static getOrgSurveyTrends(surveyList): DateWiseResponseStats[] {
    let datewiselist: DateWiseResponseStats[] = [];

    if (surveyList != null) {
      surveyList.forEach((survey) => {
        let orgSurvey = new DateWiseResponseStats();
        orgSurvey.responseDate = survey.responseDate;
        orgSurvey.responseCount = survey.responseCount;
        orgSurvey.ohbiScore = survey.ohbiScore;

        datewiselist.push(orgSurvey);
      });
    }
    return datewiselist;
  }
}
