export class Region {
    regionId: number;
    name: string;
    nameArabic: string;
    // latitude: number;
    // longitude: number;
    responsePercent: number;
    score: number;
    empCount = 0;
    leaderCount = 0;
    totalCount = 0;

    static getRegions(filterData): Region[] {
        var regions: Region[] = [];
        filterData.forEach(regionData => {
            var region = new Region();        
            region.regionId = regionData.regionId;
            region.name = regionData.name;
            region.nameArabic = regionData.nameArabic;
            // region.latitude = regionData.latitude;
            // region.longitude = regionData.longitude;
            region.responsePercent = regionData.responsePercent;
            region.score = regionData.score;

            regions.push(region);          
        });   
        return regions;
    }
}
