<div id="preloader" *ngIf="loading">
    
        
        <div class="ring">{{"Loading" | translate}}
            <span></span>
          </div>
    
    
</div>


