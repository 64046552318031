import { Component , OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from './core/services/event.service';
import { LanguageService } from './core/services/language.service';
import { DefaultComponent } from './pages/dashboards/default/default.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
  isLogin: boolean;  
  openPopup: boolean;
  currentLang: string;
  title = 'ICMS Survey';

  constructor(
    public translateService: LanguageService, 
    public translate: TranslateService, 
    private eventService: EventService,
    public languageService: LanguageService,
  ){
    // this.translateService.setLanguage('ar');
    // console.log(this.translateService);
    // let htmlTag = document.getElementsByTagName("html")[0] as HTMLElement;
    // var lang = localStorage.getItem('currentLang');
    // htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
  }

  public changeLanguageAll(lang: string) {
    //alert(lang);
    //this.translateService.setLanguage(lang);
  }

  
}
