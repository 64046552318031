export class ScoreHistory {
    icPillerId: number;
    questionId: number;
    lastYearScorePercent: number = 0;
    globalScorePercent: number = 0;

    static getGlobalStats(globalResponse): ScoreHistory[] {
        let history: ScoreHistory[] = [];
        globalResponse.forEach(score => {
            let gScore = new ScoreHistory();
            gScore.icPillerId = score.icmPillerId;
            gScore.lastYearScorePercent = score.lastYearScore;
            gScore.globalScorePercent = score.globalScore;
            history.push(gScore);
        });
        return history;
    }

    static getQuestionWiseStats(questionWiseResponse): ScoreHistory[] {
        let history: ScoreHistory[] = [];
        questionWiseResponse.forEach(score => {
            let gScore = new ScoreHistory();
            gScore.questionId = score.questionId;
            gScore.lastYearScorePercent = score.lastYearScore;
            gScore.globalScorePercent = score.globalScore;
            history.push(gScore);
        });
        return history;
    }
}
