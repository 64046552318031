export class Employeetype {
    orgEmployeeTypeId: number;
    employeeTypeName: string;
    employeeTypeNameArabic: string;

    static getEmployeeType(filterData): Employeetype[] {
        var emptypes: Employeetype[] = [];
        filterData.forEach(Data => {
            var emptype = new Employeetype();        
            emptype.orgEmployeeTypeId = Data.orgEmployeeTypeId;
            emptype.employeeTypeName = Data.employeeTypeName;
            emptype.employeeTypeNameArabic = Data.employeeTypeNameArabic;

            emptypes.push(emptype);          
        });   
        return emptypes;
    }
}
