export class SurveyResponse {
  userId: number;
  genderId: number;
  regionId: number;
  sectorId: number;
  experienceId: number;
  experienceLevel: number;
  roleId: number;
  roleCategory: number;
  answerId: number;
  answer: string;
  questionId: number;
  questionType: number;
  icpillerId: number;
  subicpillerId: number;
  employeeTypeId: number;
  empSectorId: number;
  yearsOfExp: number;
  static getSurveyResponses(surveys): SurveyResponse[] {
    var surveyArray: Array<SurveyResponse> = [];
    surveys.forEach((data) => {
      var sruveyResponse = new SurveyResponse();
      sruveyResponse.userId = data["userId"];
      sruveyResponse.genderId = data["genderId"] ?? 0;
      sruveyResponse.regionId = data["regionId"] ?? 0;
      sruveyResponse.sectorId = data["sectorId"] ?? 0;
      sruveyResponse.experienceId = data["experienceId"] ?? 0;
      sruveyResponse.yearsOfExp = data["yearsOfExp"] ?? 0;
      sruveyResponse.experienceLevel = data["experienceLevel"] ?? 0;
      sruveyResponse.roleId = data["roleId"] ?? 0;
      sruveyResponse.roleCategory = data["roleCategory"];
      sruveyResponse.answerId = data["answerId"] ?? 0;
      sruveyResponse.answer = data["answer"];
      sruveyResponse.questionId = data["questionId"] ?? 0;
      sruveyResponse.questionType = data["questionType"];
      sruveyResponse.icpillerId = data["icpiller_id"] ?? 0;
      sruveyResponse.subicpillerId = data["subicpiller_id"] ?? 0;
      sruveyResponse.employeeTypeId = data["employeeTypeId"] ?? 0;
      sruveyResponse.empSectorId = data["empSectorId"] ?? 0;
      surveyArray.push(sruveyResponse);
    });
    return surveyArray;
  }
}
