export enum APIResponse {
  Success = 0,
  Failed = 1,
  ServerError = 2,
  RecordExistAlready = 3,
}

export enum AlertType {
  Success = 1,
  Error = 2,
  Warning = 3,
}

export enum TaskViewSource {
  Dashboard = 1,
  TaskBucketView = 2,
}
export enum FileUploadType {
  organaisation = 1,
  product = 2,
  vendor = 3,
  orgShortLogo = 4,
  orgEmployeeProfile = 5
}



// export enum StatusColor {
//   Greeen = "#29de53",
//   Amper = "#de6f29",
//   Red = "#e02a65",
//   Blue = "#5d65f4",
// }
export enum StatusColor {
  Greeen = "#7cc138",
  Amper = "#ffb138",
  Red = "#e02a65",
  Blue = "var(--primary-color)",
}
export enum MaxFileSize {
  TWOMB = 0,
  FIVEMB = 1,
}
// export enum ScoreStatusColor {
//   greenColor = "#00c844",
//   grayColor = "#757575",
//   redColor = "#ff0019",
// }
export enum ScoreStatusColor {
  greenColor = "#7cc138",
  grayColor = "#757575",
  redColor = "#e02a65",
}

export enum ICPillerId {
  Maturity = 1,
  Awareness = 2,
  Engagement = 3,
  Relations = 4,
  Appriciation = 5,
  Piller1 = 1,
  Piller2 = 2,
  Piller3 = 3,
  Piller4 = 4,
  Piller5 = 5,
}

// export enum ICColor {
//   Awareness = "#ad208d",
//   Relations = "#2d4099",
//   Appriciation = "#622f91",
//   Engagement = "#5286c5",
//   Maturity = "#69af87",
// }
export enum ICColor {
  Awareness = "var(--primary-color)",
  Relations = "var(--primary-color)",
  Appriciation = "var(--primary-color)",
  Engagement = "var(--primary-color)",
  Maturity = "var(--primary-color)",
}


export enum EmployeeColor {
  Employee = "#de729a",
  Leader = "#b5cccd",
}

export enum ActionType {
  New = 1,
  Update = 2,
  Delete = 3,
}

// export enum DynamicStatusColor {
//   Static = "#b4b2b2",
//   VeryStrong = "#5a892c",
//   Strong = "#7bbf38",
//   Moderate = "#ffb138",
//   Week = "#e02a65",
//   VeryWeek = "#8f1c43",
// }
export enum DynamicStatusColor {
  Static = "#b4b2b2",
  VeryStrong = "#5a892c",
  Strong = "#7cc138",
  Moderate = "#ffb138",
  Week = "#e02a65",
  VeryWeek = "#8f1c43",
}

export enum TaskStatus {
  New = 0,
  Inprogress = 1,
  Completed = 2,
  OnHold = 3,
  Cancelled = 4,
}



export enum CommonSurveyPiller {
  Piller1 = 1,
  Piller2 = 2,
  Piller3 = 3,
  Piller4 = 4,
  Piller5 = 5,
}

export enum TaskStatusName {
  New = "New",
  Inprogress = "In Progress",
  OnHold = "On Hold",
  Completed = "Completed",
}

export enum TaskPriority {
  High = 1,
  Medium = 2,
  Low = 3,
}

export enum TaskPriorityText {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export enum SurveyType {
  OHBI = 1,
  Attraction = 2,
  Onboarding1 = 3,
  Onboarding2 = 4,
  Development = 5,
  Performance = 6,
  Exit = 7,
  Custom = 8,
  Annual = 12,

  Survey360 = 11,
}
// Attraction Survey
export enum BrandAwarenessChannel {
  CompanyWebsite = 1,
  LinkedIn = 2,
  RecruitmentAgency = 3,
  SocialMedia = 4,
  Referral = 5,
}

export enum BrandAttractionFactor {
  CompanyImage = 1,
  JobVacancy = 2,
  OrgCulture = 3,
  PayAndBenefits = 4,
  Recommendation = 5,
}

export enum UserType {
  SuperAdmin = 1,
  OrgAdmin = 2,
  Employee = 3,
}

export enum UserRole {
  SuperUser = 1,
  NormalUser = 2,
  DepartManager = 3,
}

export enum ICMIndex {
  All = 1,
  Region = 2,
  Sector = 3,
  Gender = 4,
  Level = 5,
  Experience = 6,
}

export enum QuestionType {
  SingleSelection = 1,
  MultiSelection = 2,
  Input = 3,
  FeedbackQuestion = 4,
}

export enum SurveyChartType {
  PieChart = 0,
  BarChart = 1,
}

export enum GoalType {
  OrgStrategicGoal = 1,
  OrgStrategicObjective = 2,
  OrgStrategicEvaluation = 3,
  OrgStrategicPiller= 4,
}
