import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";

import { environment } from "../environments/environment";

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

import { SharedModule } from "./cyptolanding/shared/shared.module";

import { ExtrapagesModule } from "./extrapages/extrapages.module";

import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initFirebaseBackend } from "./authUtils";
import { CyptolandingComponent } from "./cyptolanding/cyptolanding.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
// import { MatSnackBarModule } from "@angular/material/snack-bar";

import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { FakeBackendInterceptor } from "./core/helpers/fake-backend";
import { FormsModule } from "@angular/forms";
import { PagesModule } from "./pages/pages.module";
import { EmailEditorModule } from 'angular-email-editor';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { LoaderInterceptor } from "./utils/loader.interceptor";
import { UiModule } from "./pages/ui/ui.module";
import {ScheduleModule,RecurrenceEditorModule,DayService,WeekService,WorkWeekService,MonthService,MonthAgendaService} from '@syncfusion/ej2-angular-schedule'
import { ArchwizardModule } from 'angular-archwizard';
import { NgWizardModule } from '../../projects/ng-wizard/src/lib/ng-wizard.module';
import { OrganizationChartModule } from 'primeng/organizationchart';




if (environment.defaultauth === "firebase") {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, CyptolandingComponent,  
   
  ],
  imports: [
    NgWizardModule,
    ArchwizardModule,
    ScheduleModule,
    OrganizationChartModule,
    RecurrenceEditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // MatSnackBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    SharedModule,
    ScrollToModule.forRoot(),
    NgbModule,
    FormsModule,
    EmailEditorModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 3,
      innerStrokeWidth: 3,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      "showSubtitle": false,
      "showUnits": false,
      "showBackground": false,
      "responsive": true,
      "titleFontSize": "12",
      "titleFontWeight": "700",
      "space": -3,
      "outerStrokeLinecap": "square"
    }),
    UiModule
  ],
  bootstrap: [AppComponent],
  providers: [ScheduleModule,RecurrenceEditorModule,DayService,WeekService,WorkWeekService,MonthService,MonthAgendaService,
    // LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },    
  ],
  // exports: [TranslateModule],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
