export class EmpSector {
    empSectorId: number;
    name: string;
    name_arabic: string;

    static getEmpSector(filterData): EmpSector[] {
        var empSectors: EmpSector[] = [];
        filterData.forEach(Data => {
            var empSector = new EmpSector();        
            empSector.empSectorId = Data.empSectorId;
            empSector.name = Data.name;
            empSector.name_arabic = Data.name_arabic;

            empSectors.push(empSector);          
        });   
        return empSectors;
    }
}
