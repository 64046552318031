import { Injectable } from "@angular/core";
import { AlertType, APIResponse, DynamicStatusColor, ScoreStatusColor } from "../utils/app-constants";
import { TranslateService } from "@ngx-translate/core";

// import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppUtilService {
  public appTagsSubject: BehaviorSubject<any>;
  public appTags: Observable<any>;

  public appCitiesSubject: BehaviorSubject<any>;
  public appCities: Observable<any>;

  // constructor(public snackBar: MatSnackBar, public translateService: TranslateService) {

  constructor(public translateService: TranslateService) {
    this.appTagsSubject = new BehaviorSubject<any>(null);
    this.appTags = this.appTagsSubject.asObservable();

    this.appCitiesSubject = new BehaviorSubject<any>(null);
    this.appCities = this.appCitiesSubject.asObservable();
  }

  showAlert(alertType: AlertType, message: string) {
    let alertString = "";

    // let horizontalPosition: MatSnackBarHorizontalPosition = "right";
    // let verticalPosition: MatSnackBarVerticalPosition = "top";

    // if (alertType == AlertType.Error) {
    //   alertString = "error";
    // } else if (alertType == AlertType.Success) {
    //   alertString = "success";
    // } else {
    //   alertString = "warning";
    // }

    // this.snackBar.open(message, "x", {
    //   duration: 5000,
    //   panelClass: [alertString],
    //   horizontalPosition: horizontalPosition,
    //   verticalPosition: verticalPosition,
    // });
  }

  static formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes == 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  }

  writeCurrentSurveyId(surveyId) {
    localStorage.setItem("CurrentSurveyId", surveyId);
  }

  getCurrentSurveyId() {
    debugger;
    console.log(localStorage.getItem("CurrentSurveyId"));
    return localStorage.getItem("CurrentSurveyId");
  }

  getScoreColor(score) {
    if (score < 0) {
      return ScoreStatusColor.redColor;
    } else if (score > 0) {
      return ScoreStatusColor.greenColor;
    } else {
      return ScoreStatusColor.grayColor;
    }
  }

  getDynamicStatusColor(score){
    if (score == 0) {
      return DynamicStatusColor.Static;
    }
    if (score > 0 && score <= 20) {
      return DynamicStatusColor.VeryWeek;
    }
    if (score > 20 && score <= 40) {
      return DynamicStatusColor.Week;
    }
    if (score > 40 && score <= 60) {
      return DynamicStatusColor.Moderate;
    }
    if (score > 60 && score <= 80) {
      return DynamicStatusColor.Strong;
    }
    if (score > 80 && score <= 100) {
      return DynamicStatusColor.VeryStrong;
    }
  }

  showAlertMessage(alertType: AlertType, alertMessageKey: string) {
    // this.showAlert(alertType, "alerttest");

    this.translateService.get(alertMessageKey).subscribe((text: string) => {
      this.showAlert(alertType, text);
    });
  }

  isNumber(value: string | number): boolean {
    return value != null && value !== "" && !isNaN(Number(value.toString()));
  }

  // static checkMaxFileSize(files, maxFileSizeType) {
  //   var maxFileSize = 2000000;
  //   if (maxFileSizeType == MaxFileSize.FIVEMB) {
  //     maxFileSize = 5000000;
  //   }

  //   for (let i = 0; i < files.length; i++) {
  //     if (files[i].size > maxFileSize) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }
}
