<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/icms-logo-white.png" alt="" height="60">
          </span>
          <span class="logo-lg">
            <img src="assets/images/icms-logo-white.png" alt="" height="60">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/icms-icon.png" alt="" height="60">
          </span>
          <span class="logo-lg">
            <img src="assets/images/icms-logo.png" alt="" height="60">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-0 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
     

    
    </div>

    <div class="d-flex">
      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primarcolor " type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      
      <div class="dropdown d-none d-lg-inline-block">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()" style="padding-right:10px ;">
          <i class="bx bx-fullscreen pt-1"></i>
        </button>
      </div>
      
      <div class="dropdown d-none d-lg-inline-block" *ngIf="arabicLang == 'en'">
        <button class="btn header-item noti-icon p-0" (click)="changeLanguage('ar')" >
          <a class="d-flex align-items-center px-2" style="background: var(--secondary-color);padding: 6px;border-radius: 8px;">
            <i class="fas fa-globe px-1 font-size-16"></i> <span class="text-black">{{ "Arabic" | translate }}</span></a>
        </button>
      </div>  
      
      <div class="dropdown d-none d-lg-inline-block" *ngIf="arabicLang == 'ar'">
        <button class="btn header-item noti-icon p-0" (click)="changeLanguage('en')">
          <a class="d-flex align-items-center px-2" style="background: var(--secondary-color);padding: 6px;border-radius: 8px;">
            <i class="fas fa-globe px-1 font-size-16"></i> <span class="text-black">{{ "En" | translate }}</span></a>
        </button>
      </div>  

      <div class="dropdown d-none d-lg-inline-block" *ngIf="darkmodeVar == 'light'">
        <button type="button"  class="btn header-item noti-icon" (click)="darkMode('dark')">
          <i class="fas fa-moon font-size-16" style="background: var(--secondary-color);padding: 7px 8px 7px 9px;border-radius: 8px;"></i>
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block" *ngIf="darkmodeVar == 'dark'">
        <button type="button"  class="btn header-item noti-icon" (click)="darkMode('light')">
          <i class="fas fa-lightbulb font-size-16" style="background: var(--secondary-color);padding: 7px 8px 7px 9px;border-radius: 8px;"></i>
        </button>
      </div>

      

      <div class="dropdown d-inline-block borderleftstyle" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/usericon.jpeg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1 text-black">{{ 'adminUser' | translate}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" style="left: 0 !important;"  aria-labelledby="page-header-user-dropdown" ngbDropdownMenu>
          <!-- item-->
          <!-- <a class="dropdown-item" routerLink="/contacts/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i>
              {{ 'HEADER.LOGIN.SETTINGS' | translate }} </a> -->
          
          <!-- <div class="dropdown-divider" style="margin: 0 !important;"></div> -->
          <a class="dropdown-item text-danger" href="javascript: void(0);" routerLink="/changePassword" [ngClass]="arabicLang == 'ar' ? 'ar_logoutstyle': ''" (click)="changePassword()">
            <i class="bx bx-lock font-size-16 align-middle me-1 text-danger"></i>
            {{ 'changePassword' | translate}}
        </a>        
          <a class="dropdown-item text-danger" href="javascript: void(0);" [ngClass]="arabicLang == 'ar' ? 'ar_logoutstyle': ''" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              {{ 'Logout' | translate}}</a>
        </div>
      </div>

      

    </div>
  </div>
</header>