export class Sector {
    sectorId: number;
    name: string;
    nameArabic: string;

    empCount = 0;
    leaderCount = 0;
    totalCount = 0;

    empCountPercent = "0%";
    leadercountPercent = "0%";

    static getSectors(filterData): Sector[] {
        var sectors: Sector[] = [];
        filterData.forEach(sectorData => {
            var sector = new Sector();
            sector.sectorId = sectorData.sectorId;
            sector.name = sectorData.name;
            sector.nameArabic = sectorData.name_arabic;

            sectors.push(sector);
        });
        return sectors;
    }
}
