export class GraphDashboard {
    leaderMaturity: number = 0;
    employeeMaturity: number = 0;
    overallMaturity: number = 0;
    globalLeaderMaturity: number = 0.0;
    globalEmployeeMaturity: number = 0.0;

    leaderAwareness: number = 0;
    employeeAwareness: number = 0;
    overallAwareness: number = 0;
    globalLeaderAwareness: number = 0.0;
    globalEmployeeAwareness: number = 0.0;

    leaderEnagement: number = 0;
    employeeEngaement: number = 0;
    overallEnagement: number = 0;
    globalLeaderEnagement: number = 0.0;
    globalEmployeeEngaement: number = 0.0;

    leaderRelation: number = 0;
    employeeRelation: number = 0;
    overallRelation: number = 0;
    globalLeaderRelation: number = 0.0;
    globalEmployeeRelation: number = 0.0;

    leaderAppriciation: number = 0;
    employeeApprication: number = 0;
    overallAppriciation: number = 0;
    globalLeaderAppriciation: number = 0;
    globalEmployeeApprication: number = 0;
}
