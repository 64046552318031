export const environment = {
  production: false,
  defaultauth: "fackbackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },

  domainName: "https://icmspro.live/ohbiapi/",

  // domainName: "http://localhost:3000/ohbiapi/",
  // domainName: "https://icms.live/ohbiapi/",
  // domainName: "https://annztech.com/ohbiapi/",

  loginURL: "userLogin",
  surveyDashboardURL: "getOrgSurveyDashboard",
  orgAdminDashboardURL: "getOrgAdminDashboard",

  //ADD SURVEY
  getOrganisationListURL: "getOrganisationList",
  // createNewSurveyURL: "updateSurveyQuestion",
  createNewSurveyURL: "addOrUpdateOrgSurvey",
  getQuestionListURL: "getOrgQuestionRepo",
  getInformativequestionURL: "getOrgInformativeQuestions",
  getSurveyListURL: "getOrgSurveyList",

  //CLONE SURVEY
  cloneExistingSurveyURL: "cloneExistingSurvey",

  //SECTOR

  getSectorListURL: "getOrgSectorsList",
  addOrUpdateSectorURL: "addOrUpdateOrgSectors",
  deleteSectorURL: "deleteOrgSectors",

  //ROLES

  getEmpRoleListURL: "getOrgEmployeeRoleList",
  createNewRoleURL: "addOrUpdateOrgEmployeeRole",
  deleteRoleURL: "deleteEmpRole",

  //REGIONS

  getRegionListURL: "getOrgRegionsList",
  addOrUpdateRegionURL: "addOrUpdateOrgRegions",
  deleteRegionURL: "deleteOrgRegions",

  //EMPLOYEE
  getOrgEmployeeListURL: "getOrgEmployeeList",
  getOrgEmployeeDetailURL: "getOrgEmployeeDetail",
  createNewEmployeeURL: "addOrUpdateOrgEmployee",
  updateEmpWebportalAccessURL: "updateEmpWebportalAccess",

  updateSurveyEmailTemplateURL: "updateSurveyEmailTemplate",
  updateSurveyEmailTemplateContentURL: "updateSurveyEmailTemplateContent",

  activateOrInactivateOrgRegionURL: "activateOrInactivateOrgRegion",
  activateOrInactivateOrgSectorURL: "activateOrInactivateOrgSector",
  activateOrInactivateOrgEmployeeRoleURL: "activateOrInactivateOrgEmployeeRole",
  activateOrInactivateOrgEmployeeURL: "activateOrInactivateOrgEmployee",

  //EMPLOYEE TYPE
  getOrgEmployeeTypeListURL: "getOrgEmployeeTypeList",
  createOrgEmployeeTypeURL: "createOrgEmployeeType",
  activateOrInactivateOrgEmployeeTypeURL: "activateOrInactivateOrgEmployeeType",

  //ORG DATA IN MY ORGANISATION
  getOrgDashboardDetailsURL: "getOrgDashboardDetails",
  getOrgDashboardURL: "getOrgDashboard",

  //MY SURVEY
  getOrgSurveyDashboardDetailsURL: "getOrgSurveyDashboardDetails",
  getOrgNonOHBISurveyDashboardDetailsURL: "getOrgNonOHBISurveyDashboardDetails",
  sendSurveyToEmployeesURL: "sendSurveyToEmployees",
  sendTestSurvey: "sendTestSurvey",
  getOrgSurveyDetailsURL: "getOrgSurveyDetails",
  sendSurveyToEmployeesBySearchURL: "sendSurveyToEmployeesBySearch",
  searchOrgEmployeeURL: "searchOrgEmployee",

  sendOrgSurveyRemainderURL: "sendOrgSurveyReminder",

  uploadSurveyImageURL: "uploadSurveyImage",

  //Task Planner
  addOrUpdateTaskBucketURL: "addOrUpdateBucket",
  addOrUpdateUsersToTaskBucketURL: "addOrUpdateBucketUser",
  addOrUpdateTaskToTaskBucketURL: "addOrUpdateTask",
  addOrUpdateSubTaskToTaskURL: "addOrUpdateSubtask",
  addOrUpdateCommentTotTaskURL: "addOrUpdateComment",
  getBucketTaskListURL: "taskListInABucket",
  getOrganisationTaskBucketListURL: "getBucketListForOrganization",
  getOrganisationDashBoardURL: "getOrganisationDashBoard",
  getBucketDetailsURL: "getBucketDetails",
  getTaskDetailsURL: "getTaskDetails",
  getSubTaskDetailsURL: "getSubTaskDetails",
  deleteBucketURL: "deleteBucket",
  deleteTaskURL: "deleteTask",
  deleteSubTaskURL: "deleteSubtask",
  deleteCommentURL: "deleteComment",
  addTaskAttachmentURL: "addOrUpdateTaskAttachement",
  updateTaskStatusURL: "updateTaskStatus",
  updateSubTaskStatusURL: "updateSubtaskStatus",
  getBucketUserListURL: "getBucketUserList",

  //Non OHBI Survey
  getAttractionSurveyDashbordURL: "getAttractionSurveyDashbord",
  getOnboarding1DashbordURL: "getOnboarding1Dashbord",
  getOnboarding2DashbordURL: "getOnboarding2Dashbord",
  getDevelopmentSurveyDashbordURL: "getDevelopmentSurveyDashbord",
  getRetentionSurveyDashbordURL: "getRetentionSurveyDashbord",
  getExitSurveyDashbordURL: "getExitSurveyDashbord",
  getNonOHBISurveyStatsURL: "getNonOHBISurveyStats",
  getOrgNonOHBISurveyQuestionsURL: "getOrgNonOHBISurveyQuestions",
  sendAttractionSurveyInviteURL: "sendAttractionSurveyInvite",
  createNonOHBISurveyURL: "createNonOHBISurvey",
  setAutoSurveyInviteURL: "setAutoSurveyInvite",
  sendNonOHBISurveyToEmployeesBySearchURL:
    "sendNonOHBISurveyToEmployeesBySearch",
  sendNonOHBISurveyToEmployees: "sendNonOHBISurveyToEmployees",
  getRecentlyJoinedEmpListURL: "getRecentlyJoinedEmpList",
  sendOnboardSurveyInviteURL: "sendNonOHBISurveyToEmployeesBySearch",
  getOtherNonOHBISurveyListURL: "getOtherNonOHBISurveyList",
  sendNonOHBISurveyToEmployeesURL: "sendNonOHBISurveyToEmployees",
  sendSurveyThroughEmailURL: "sendSurveyThroughEmail",
  getOtherNonOHBISurveyDetailsURL: "getOtherNonOHBISurveyDetails",
  getNonOHBISurveyListURL: "getNonOHBISurveyList",
  getCustomSurveyDashbordURL: "getCustomSurveyDashbord",
  createCustomSurveyURL: "createCustomSurvey",
  editCustomSurveyDetailURL: "editCustomSurveyDetail",
  getCustomSurveyDetailsURL: "getCustomSurveyDetails",
  sendNonOHBISurveyReminderURL: "sendNonOHBISurveyReminder",
  deleteOrgSurveyURL: "deleteOrgSurvey",
  //ICM Store Vendor API
  getOrgOrdersListURL: "getOrgOrdersList",
  getICMStoreProductLandingContentURL: "getICMStoreProductLandingContent",
  filterProductURL: "filterProduct",
  createProductOrderForMultiEmpURL: "createProductOrderForMultiEmp",
  getOrgOrderDetailsURL: "getOrgOrderDetails",
  sendOrgGiftValueRequestURL: "sendOrgGiftValueRequest",
  updateTotalOrderStatusURL: "updateTotalOrderStatus",

  //theme configuration
  updateOrgSettingsURL: "updateOrgSettings",
  getOrgSettingsURL: "getOrgSettings",
  fileUploadURL: "fileUpload",

  //Update Password
  changePasswordOrgURL: "changePasswordOrg",

  //transaction Details of Gift
  getOrgGiftValueHistoryURL: "getOrgGiftValueHistory",
  getSuggestionURL: "integrateBot",

  // Update Multi Employee Role Rank
  updateMultiEmpRoleRankURL: "updateMultiEmpRoleRank",

  updateEmpSupervisorURL: "updateEmpSupervisor",
  //Get DepartmentList
  getDeptEmployeeListURL: "getDeptEmployeeList",
  getSurveyCoverageURL: "getSurveyCoverage",
  sendSurveyInviteURL: "addOrUpdate360SurveyInviteRecord",

  get360SurveyInviteRecordListURL: "get360SurveyInviteRecordList",
  send360SurveyInviteToEmpURL: "send360SurveyInviteToEmp",

  get360SurveyListURL: "get360SurveyList",
  getrecalculateSurveyCoverageValueURL: "recalculateSurveyCoverageValue",

  get360DegreeOrgDashboardURL: "get360DegreeOrgDashboard",
  getOrgSurveyInviteEmailTemplateURL: "getOrgSurveyInviteEmailTemplate",
  getGoalCategoryListURL: "getOrgGoalCategoryList",
  getGoalPillerListURL: "getOrgGoalPillerList",
  createGoalPillerListURL: "addOrUpdateOrgGoalPiller",
  getGoalSubCategoryListURL: "getOrgGoalSubCategoryList",
  createGoalCategoryListURL: "addOrUpdateOrgGoalCategory",
  createGoalSubCategoryListURL: "addOrUpdateOrgGoalSubCategory",

  activateOrDeactivateGoalCategoryURL: "activeOrInactiveOrgGoal",
  getGoalListURL: "getOrgGoalList",
  createGoalListURL: "addOrUpdateOrgGoal",
  getOrgAnnualEvaluationListURL: "getOrgAnnualEvaluationList",
  addOrUpdateOrgAnnualEvaluationURL: "addOrUpdateOrgAnnualEvaluation",
  validateAutoSurveyInviteURL: "validateAutoSurveyInvite",
  getAutoSurveyInviteListURL: "getAutoSurveyInviteList",

  //Org Strategic Goal
  addOrUpdateOrgStrategicPillerURL: "addOrUpdateOrgStrategicPiller",
  getOrgStartegicPillerListURL: "getOrgStartegicPillerList",
  addOrUpdateOrgStrategicObjectivesURL: "addOrUpdateOrgStrategicObjectives",
  getOrgStrategicObjectivesListURL: "getOrgStrategicObjectivesList",
  deleteOrgStrategicGoalURL: "deleteOrgStrategicGoal",

  getOrgStrategicGoalListURL: "getOrgStrategicGoalList",
  addOrUpdateOrgStrategicEvaluationURL: "addOrUpdateOrgStrategicEvaluation",
  getOrgStrategicEvaluationListURL: "getOrgStrategicEvaluationList",
  getOrgStrategicEvaluationDetailURL: "getOrgStrategicEvaluationDetail",
  initiateStrategicEmpGoalURL: "initiateStrategicEmpGoal",

  activeOrInactiveOrgStrategicGoalURL: "activeOrInactiveOrgStrategicGoal",
  addOrUpdateOrgStrategicGoalURL: "addOrUpdateOrgStrategicGoal",
  getOrgStrategicGoalDashboardURL: "getOrgStrategicGoalDashboard",

  //Org Performance Goal
  addOrUpdateOrgPerformancePillerURL: "addOrUpdateOrgPerformancePiller",
  getOrgPerformancePillerListURL: "getOrgPerformancePillerList",
  addOrUpdateOrgPerformanceObjectivesURL: "addOrUpdateOrgPerformanceObjectives",
  getOrgPerformanceObjectivesListURL: "getOrgPerformanceObjectivesList",
  addOrUpdateOrgPerformanceEvaluationURL: "addOrUpdateOrgPerformanceEvaluation",
  getOrgPerformanceEvaluationListURL: "getOrgPerformanceEvaluationList",
  getOrgPerformanceEvaluationDetailURL: "getOrgPerformanceEvaluationDetail",
  activeOrInactiveOrgPerformanceGoalURL: "activeOrInactiveOrgPerformanceGoal",
  getOrgPerformanceGoalListURL: "getOrgPerformanceGoalList",
  addOrUpdateOrgPerformanceGoalURL: "addOrUpdateOrgPerformanceGoal",
  deleteOrgPerformanceGoalURL: "deleteOrgPerformanceGoal",
  initiatePerformanceEmpGoalURL: "initiatePerformanceEmpGoal",
  getOrgPerformanceEvaluationResultsURL: "getOrgPerformanceEvaluationResults",
  getOrgPerformanceEvaluationForMilestoneURL:"getOrgPerformanceEvaluationForMilestone",
  getEmployeePerformanceForMilestoneURL: "getEmpOrgPerformanceGoalList",
  getOrgFilterListURL: "getOrgFilterList",
  addOrUpdateOrgEmpPerformanceMultyGoalURL:"addOrUpdateOrgEmpPerformanceMultyGoal",
  getEmpGroupGoalListURL:"getEmpGroupGoalList"
};
