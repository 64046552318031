<div style="height: 60px"></div>

<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        {{ year }} © {{ "ICM" | translate }}
        {{ "Allrightsreserved" | translate }}
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block"></div>
      </div>
    </div>
  </div>
</footer>
