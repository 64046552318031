export class OverseasStats {
  orgOverallMean: number ;
  orgOverallLeaderMean: number ;
  orgOverallEmployeeMean: number ;

  overallMaturity: number;
  overallAwareness: number ;
  overallEnagement: number;
  overallRelation: number ;
  overallAppriciation: number ;

  overallOrganisationScore: number ;
  overallLastYearScore: number;
  overallGlobalScore: number;
  overallLastYearScoreDiff: number ;
  overallGlobalScoreDiff: number;

  matutiryGlobalScore: number ;
  matutiryLastYearScore: number ;
  matutiryGlobalDiff: number ;
  matutiryLastYearDiff: number;

  awarenessGlobalScore: number ;
  awarenessLastYearScore: number ;
  awarenessGlobalDiff: number ;
  awarenessLastYearDiff: number;

  engagementGlobalScore: number;
  engagementLastYearScore: number;
  engagementGlobalDiff: number;
  engagementLastYearDiff: number;

  relationGlobalScore: number;
  relationLastYearScore: number;
  relationGlobalDiff: number;
  relationLastYearDiff: number;

  appriciationGlobalScore: number;
  appriciationLastYearScore: number ;
  appriciationGlobalDiff: number ;
  appriciationLastYearDiff: number;

  employeeMaturity: number ;
  leaderMaturity: number ;

  employeeAwareness: number;
  leaderAwareness: number ;

  employeeEnagement: number;
  leaderEnagement: number ;

  employeeRelation: number;
  leaderRelation: number ;

  employeeAppriciation: number ;
  leaderAppriciation: number;
}

// const overseasStatsArray: OverseasStats[] = [];

// for (const dummyStats of dummyorgOverallStats) {
//   const stats = new OverseasStats();  
 
//   stats.orgOverallMean = dummyStats.orgOverallMean;
//   stats.orgOverallLeaderMean = dummyStats.orgOverallLeaderMean;
//   stats.orgOverallEmployeeMean = dummyStats.orgOverallEmployeeMean;
//   stats.overallMaturity = dummyStats.overallMaturity;
//   stats.overallAwareness = dummyStats.overallAwareness;
//   stats.overallEnagement = dummyStats.overallEnagement;
//   stats.overallRelation = dummyStats.overallRelation;
//   stats.overallAppriciation = dummyStats.overallAppriciation;
//   stats.overallOrganisationScore = dummyStats.overallOrganisationScore;
//   stats.overallLastYearScore = dummyStats.overallLastYearScore;
//   stats.overallGlobalScore = dummyStats.overallGlobalScore;
//   stats.overallLastYearScoreDiff = dummyStats.overallLastYearScoreDiff;
//   stats.overallGlobalScoreDiff = dummyStats.overallGlobalScoreDiff;
//   stats.matutiryGlobalScore = dummyStats.matutiryGlobalScore;
//   stats.matutiryLastYearScore = dummyStats.matutiryLastYearScore;
//   stats.matutiryGlobalDiff = dummyStats.matutiryGlobalDiff;
//   stats.matutiryLastYearDiff = dummyStats.matutiryLastYearDiff;
//   stats.awarenessGlobalScore = dummyStats.awarenessGlobalScore;
//   stats.awarenessLastYearScore = dummyStats.awarenessLastYearScore;
//   stats.awarenessGlobalDiff = dummyStats.awarenessGlobalDiff;
//   stats.awarenessLastYearDiff = dummyStats.awarenessLastYearDiff;
//   stats.engagementGlobalScore = dummyStats.engagementGlobalScore;
//   stats.engagementLastYearScore = dummyStats.engagementLastYearScore;
//   stats.engagementGlobalDiff = dummyStats.engagementGlobalDiff;
//   stats.engagementLastYearDiff = dummyStats.engagementLastYearDiff;
//   stats.relationGlobalScore = dummyStats.relationGlobalScore;
//   stats.relationLastYearScore = dummyStats.relationLastYearScore;
//   stats.relationGlobalDiff = dummyStats.relationGlobalDiff;
//   stats.relationLastYearDiff = dummyStats.relationLastYearDiff;
//   stats.appriciationGlobalScore = dummyStats.appriciationGlobalScore;
//   stats.appriciationLastYearScore = dummyStats.appriciationLastYearScore;
//   stats.appriciationGlobalDiff = dummyStats.appriciationGlobalDiff;
//   stats.appriciationLastYearDiff = dummyStats.appriciationLastYearDiff;
//   stats.employeeMaturity = dummyStats.employeeMaturity;
//   stats.leaderMaturity = dummyStats.leaderMaturity;
//   stats.employeeAwareness = dummyStats.employeeAwareness;
//   stats.leaderAwareness = dummyStats.leaderAwareness;
//   stats.employeeEnagement = dummyStats.employeeEnagement;
//   stats.leaderEnagement = dummyStats.leaderEnagement;
//   stats.employeeRelation = dummyStats.employeeRelation;
//   stats.leaderRelation = dummyStats.leaderRelation;
//   stats.employeeAppriciation = dummyStats.employeeAppriciation;
//   stats.leaderAppriciation = dummyStats.leaderAppriciation;
  

//   overseasStatsArray.push(stats);
//   console.log(overseasStatsArray)
// }



var dummyorgOverallStats= [
  {
    "orgOverallMean": 78.5,
    "orgOverallLeaderMean": 85.2,
    "orgOverallEmployeeMean": 71.8,
    "overallMaturity": 92.3,
    "overallAwareness": 87.6,
    "overallEnagement": 68.4,
    "overallRelation": 78.9,
    "overallAppriciation": 81.0,
    "overallOrganisationScore": 81.4,
    "overallLastYearScore": 79.9,
    "overallGlobalScore": 82.7,
    "overallLastYearScoreDiff": 1.5,
    "overallGlobalScoreDiff": -1.3,
    "matutiryGlobalScore": 88,
    "matutiryLastYearScore": 86,
    "matutiryGlobalDiff": 2,
    "matutiryLastYearDiff": -1,
    "awarenessGlobalScore": 82,
    "awarenessLastYearScore": 85,
    "awarenessGlobalDiff": -3,
    "awarenessLastYearDiff": 2,
    "engagementGlobalScore": 76,
    "engagementLastYearScore": 78,
    "engagementGlobalDiff": -2,
    "engagementLastYearDiff": 1,
    "relationGlobalScore": 79,
    "relationLastYearScore": 80,
    "relationGlobalDiff": -1,
    "relationLastYearDiff": 1,
    "appriciationGlobalScore": 83,
    "appriciationLastYearScore": 82,
    "appriciationGlobalDiff": 1,
    "appriciationLastYearDiff": -1,
    "employeeMaturity": 72.5,
    "leaderMaturity": 85.7,
    "employeeAwareness": 68.9,
    "leaderAwareness": 89.2,
    "employeeEnagement": 67.3,
    "leaderEnagement": 90.1,
    "employeeRelation": 76.8,
    "leaderRelation": 82.4,
    "employeeAppriciation": 80.5,
    "leaderAppriciation": 84.3
  },
  {
    "orgOverallMean": 81.2,
    "orgOverallLeaderMean": 88.3,
    "orgOverallEmployeeMean": 76.5,
    "overallMaturity": 90.7,
    "overallAwareness": 88.5,
    "overallEnagement": 71.3,
    "overallRelation": 82.0,
    "overallAppriciation": 79.6,
    "overallOrganisationScore": 82.1,
    "overallLastYearScore": 80.4,
    "overallGlobalScore": 83.9,
    "overallLastYearScoreDiff": 1.8,
    "overallGlobalScoreDiff": -1.5,
    "matutiryGlobalScore": 86,
    "matutiryLastYearScore": 84,
    "matutiryGlobalDiff": 2,
    "matutiryLastYearDiff": -1,
    "awarenessGlobalScore": 83,
    "awarenessLastYearScore": 87,
    "awarenessGlobalDiff": -4,
    "awarenessLastYearDiff": 3,
    "engagementGlobalScore": 77,
    "engagementLastYearScore": 80,
    "engagementGlobalDiff": -3,
    "engagementLastYearDiff": 2,
    "relationGlobalScore": 81,
    "relationLastYearScore": 83,
    "relationGlobalDiff": -2,
    "relationLastYearDiff": 2,
    "appriciationGlobalScore": 84,
    "appriciationLastYearScore": 82,
    "appriciationGlobalDiff": 2,
    "appriciationLastYearDiff": -1,
    "employeeMaturity": 74.8,
    "leaderMaturity": 86.9,
    "employeeAwareness": 70.2,
    "leaderAwareness": 88.4,
    "employeeEnagement": 68.7,
    "leaderEnagement": 90.3,
    "employeeRelation": 79.3,
    "leaderRelation": 83.1,
    "employeeAppriciation": 81.2,
    "leaderAppriciation": 85.6
  },
  {
    "orgOverallMean": 79.7,
    "orgOverallLeaderMean": 86.1,
    "orgOverallEmployeeMean": 74.5,
    "overallMaturity": 91.8,
    "overallAwareness": 86.7,
    "overallEnagement": 69.9,
    "overallRelation": 80.5,
    "overallAppriciation": 80.2,
    "overallOrganisationScore": 82.9,
    "overallLastYearScore": 81.4,
    "overallGlobalScore": 84.3,
    "overallLastYearScoreDiff": 1.5,
    "overallGlobalScoreDiff": -1.4,
    "matutiryGlobalScore": 87,
    "matutiryLastYearScore": 85,
    "matutiryGlobalDiff": 2,
    "matutiryLastYearDiff": -1,
    "awarenessGlobalScore": 82,
    "awarenessLastYearScore": 86,
    "awarenessGlobalDiff": -4,
    "awarenessLastYearDiff": 3,
    "engagementGlobalScore": 75,
    "engagementLastYearScore": 79,
    "engagementGlobalDiff": -4,
    "engagementLastYearDiff": 2,
    "relationGlobalScore": 80,
    "relationLastYearScore": 82,
    "relationGlobalDiff": -2,
    "relationLastYearDiff": 2,
    "appriciationGlobalScore": 83,
    "appriciationLastYearScore": 81,
    "appriciationGlobalDiff": 2,
    "appriciationLastYearDiff": -1,
    "employeeMaturity": 71.2,
    "leaderMaturity": 87.2,
    "employeeAwareness": 69.4,
    "leaderAwareness": 88.7,
    "employeeEnagement": 67.5,
    "leaderEnagement": 90.5,
    "employeeRelation": 77.8,
    "leaderRelation": 82.7,
    "employeeAppriciation": 82.4,
    "leaderAppriciation": 86.2
  },
  {
    "orgOverallMean": 80.9,
    "orgOverallLeaderMean": 87.5,
    "orgOverallEmployeeMean": 75.7,
    "overallMaturity": 92.5,
    "overallAwareness": 88.9,
    "overallEnagement": 70.6,
    "overallRelation": 81.7,
    "overallAppriciation": 81.9,
    "overallOrganisationScore": 82.7,
    "overallLastYearScore": 80.8,
    "overallGlobalScore": 84.1,
    "overallLastYearScoreDiff": 2.1,
    "overallGlobalScoreDiff": -1.4,
    "matutiryGlobalScore": 88,
    "matutiryLastYearScore": 86,
    "matutiryGlobalDiff": 2,
    "matutiryLastYearDiff": -1,
    "awarenessGlobalScore": 84,
    "awarenessLastYearScore": 87,
    "awarenessGlobalDiff": -3,
    "awarenessLastYearDiff": 2,
    "engagementGlobalScore": 78,
    "engagementLastYearScore": 81,
    "engagementGlobalDiff": -3,
    "engagementLastYearDiff": 2,
    "relationGlobalScore": 82,
    "relationLastYearScore": 84,
    "relationGlobalDiff": -2,
    "relationLastYearDiff": 2,
    "appriciationGlobalScore": 85,
    "appriciationLastYearScore": 83,
    "appriciationGlobalDiff": 2,
    "appriciationLastYearDiff": -1,
    "employeeMaturity": 73.5,
    "leaderMaturity": 88.1,
    "employeeAwareness": 70.9,
    "leaderAwareness": 88.9,
    "employeeEnagement": 68.9,
    "leaderEnagement": 90.7,
    "employeeRelation": 78.2,
    "leaderRelation": 83.8,
    "employeeAppriciation": 83.7,
    "leaderAppriciation": 86.8
  },
  {
    "orgOverallMean": 79.3,
    "orgOverallLeaderMean": 85.6,
    "orgOverallEmployeeMean": 73.8,
    "overallMaturity": 90.1,
    "overallAwareness": 87.3,
    "overallEnagement": 68.9,
    "overallRelation": 79.2,
    "overallAppriciation": 79.7,
    "overallOrganisationScore": 82.3,
    "overallLastYearScore": 80.1,
    "overallGlobalScore": 83.5,
    "overallLastYearScoreDiff": 1.8,
    "overallGlobalScoreDiff": -1.2,
    "matutiryGlobalScore": 86,
    "matutiryLastYearScore": 84,
    "matutiryGlobalDiff": 2,
    "matutiryLastYearDiff": -1,
    "awarenessGlobalScore": 82,
    "awarenessLastYearScore": 86,
    "awarenessGlobalDiff": -4,
    "awarenessLastYearDiff": 3,
    "engagementGlobalScore": 76,
    "engagementLastYearScore": 80,
    "engagementGlobalDiff": -4,
    "engagementLastYearDiff": 2,
    "relationGlobalScore": 80,
    "relationLastYearScore": 82,
    "relationGlobalDiff": -2,
    "relationLastYearDiff": 2,
    "appriciationGlobalScore": 83,
    "appriciationLastYearScore": 81,
    "appriciationGlobalDiff": 2,
    "appriciationLastYearDiff": -1,
    "employeeMaturity": 71.7,
    "leaderMaturity": 87.9,
    "employeeAwareness": 70.0,
    "leaderAwareness": 88.3,
    "employeeEnagement": 68.2,
    "leaderEnagement": 90.2,
    "employeeRelation": 77.1,
    "leaderRelation": 83.0,
    "employeeAppriciation": 82.1,
    "leaderAppriciation": 86.4
  }
]
