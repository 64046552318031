<div>
    <div class="container-fluid p-0">
        <div class="row g-0">

            <div class="col-xl-9">
                <div class="auth-full-bg pt-lg-5 p-4">
                    <div class="w-100">
                        <div class="bg-overlay"></div>
                        <div class="d-flex h-100 flex-column">

                            <div class="p-4 mt-auto">
                                <div class="row justify-content-center">
                                    <div class="col-lg-7">
                                        <div class="text-center">

                                            <h4 class="mb-3"><i
                                                    class="bx bxs-quote-alt-left text-primarycolor h1 align-middle me-3"></i><span
                                                    class="text-primarycolor">5k</span>+ Satisfied clients</h4>

                                            <div dir="ltr">
                                                <owl-carousel-o class="owl-theme auth-review-carousel"
                                                    [options]="carouselOption">
                                                    <ng-template carouselSlide>
                                                        <div class="item">
                                                            <div class="py-3">
                                                                <p class="font-size-16 mb-4">" Fantastic theme with a
                                                                    ton of
                                                                    options. If you just want the HTML to integrate with
                                                                    your project, then this is the package. You can find
                                                                    the
                                                                    files in the 'dist' folder...no need to install git
                                                                    and
                                                                    all the other stuff the documentation talks about. "
                                                                </p>

                                                                <div>
                                                                    <h4 class="font-size-16 text-primarycolor">Abs1981</h4>
                                                                    <p class="font-size-14 mb-0">- Skote User</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-template>
                                                    <ng-template carouselSlide>
                                                        <div class="item">
                                                            <div class="py-3">
                                                                <p class="font-size-16 mb-4">" If Every Vendor on Envato
                                                                    are
                                                                    as supportive as Themesbrand, Development with be a
                                                                    nice
                                                                    experience. You guys are Wonderful. Keep us the good
                                                                    work. "</p>

                                                                <div>
                                                                    <h4 class="font-size-16 text-primarycolor">nezerious</h4>
                                                                    <p class="font-size-14 mb-0">- Skote User</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ng-template>
                                                </owl-carousel-o>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->

            <div class="col-xl-3">
                <div class="auth-full-page-content p-md-5 p-4">
                    <div class="w-100">

                        <div class="d-flex flex-column h-100">
                            <div class="mb-4 mb-md-5">
                                <a routerLink="/" class="d-block auth-logo">
                                    <img src="assets/images/logo-dark.png" alt="" height="18" class="auth-logo-dark">
                                    <img src="assets/images/logo-light.png" alt="" height="18" class="auth-logo-light">
                                </a>
                            </div>
                            <div class="my-auto">

                                <div>
                                    <h5 class="text-primarycolor">Lock screen</h5>
                                    <p class="text-muted">Enter your password to unlock the screen!</p>
                                </div>

                                <div class="mt-4">
                                    <form class="form-horizontal">

                                        <div class="user-thumb text-center mb-4">
                                            <img src="assets/images/users/avatar-1.jpg"
                                                class="rounded-circle img-thumbnail avatar-md" alt="thumbnail">
                                            <h5 class="font-size-15 mt-3">Maria Laird</h5>
                                        </div>


                                        <div class="mb-3">
                                            <label for="userpassword">Password</label>
                                            <input type="password" class="form-control" id="userpassword"
                                                placeholder="Enter password">
                                        </div>

                                        <div class="text-end">
                                            <button class="btn btn-primarcolor  w-md waves-effect waves-light"
                                                type="submit">Unlock</button>
                                        </div>
                                    </form>
                                    <div class="mt-5 text-center">
                                        <p>Not you ? return <a routerLink="/account/login-2"
                                                class="fw-medium text-primarycolor"> Sign In </a> </p>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4 mt-md-5 text-center">
                                <p class="mb-0">©{{year}}Skote. Crafted with <i class="mdi mdi-heart text-danger"></i>
                                    by Themesbrand</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container-fluid -->
</div>