import { Injectable,Output,EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  @Output() aClickedEvent = new EventEmitter<string>();

AClicked(msg: string) {
  this.currentLanguage = msg; 
  this.aClickedEvent.emit(msg);
}
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru', 'ar'];
  private currentLanguage: string;

  constructor(public translate: TranslateService, private cookieService: CookieService) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check('lang')) {
      browserLang = this.cookieService.get('lang');
    }
    else if(localStorage.getItem("currentLang") != null){
      browserLang = localStorage.getItem("currentLang");
    }
    else {
      this.setLanguage('en');
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru|ar/) ? browserLang : 'en');
    // translate.use('en');
    let htmlTag = document.getElementsByTagName("html")[0] as HTMLElement;
    htmlTag.dir = browserLang=== "ar" ? "rtl" : "ltr";
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.cookieService.set('lang', lang);
    this.currentLanguage = lang;    
  }
  getCurrentLanguage(): string {  
    this.currentLanguage='en';
    return this.currentLanguage;
  }

}
