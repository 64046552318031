import * as moment from "moment";

export class OrgSurvey {
  surveyId: number = 0;
  surveyName: string = "";
  surveyNameArabic: string = "";
  surveyScore: number = 0;

  static getOrgSurveyTrends(surveyList): OrgSurvey[] {
    let orgSurveyList: OrgSurvey[] = [];

    if (surveyList != null) {
      surveyList.forEach((survey) => {
        let orgSurvey = new OrgSurvey();
        orgSurvey.surveyId = survey.surveyId;
        orgSurvey.surveyName = survey.surveyName;
        orgSurvey.surveyNameArabic = survey.surveyNameArabic;
        orgSurvey.surveyScore = survey.score;
        orgSurveyList.push(orgSurvey);
      });
    }
    return orgSurveyList;
  }
}
