import { Component,Injectable, OnInit, Output, EventEmitter, Inject,ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { DefaultComponent } from "src/app/pages/dashboards/default/default.component";
import { AppComponent } from "src/app/app.component";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Survey } from "../../model/home/survey.model";
import { SurveyDashboard } from "../../model/survey-dashboard.model";
import { AlertType, APIResponse, ICPillerId } from "../../utils/app-constants";
import { AppUtilService } from "../../service/app-util.service";
import { AppService } from "../../service/app.service";
import { DashboardService } from "../../service/dashboard.service";
import { GraphDashboard } from "../../model/graph-dashboard.model";
import { OverseasStats } from "../../model/overseas-stats.model";
import { FilterData } from "src/app/model/filter-data.model";

declare let $: any;

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {


  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  private _unsubscribeAll: Subject<any>;
  surveyList: Survey[];
  userData: any;
  surveyDashboard: SurveyDashboard;
  fiterData: FilterData;

  graphDashboard = new GraphDashboard();
  orgOverallStats = new OverseasStats();
  surveyResponse: any;

  arabicLang;
  darkmodeVar = "light";

  constructor(
    @Inject(DOCUMENT)
    private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private dashboardService: DashboardService,
    private _appService: AppService,
    private _appUtil: AppUtilService,
    private translateService: TranslateService,
    public AppScreen: AppComponent,
    private cdr: ChangeDetectorRef
  ) {
    this._unsubscribeAll = new Subject();

    this.dashboardService.authenticationData.pipe(takeUntil(this._unsubscribeAll)).subscribe((authData) => {
      if (authData != null) {
        this.userData = authData;
        this.getFilterData();

      }
    });

    // this.dashboardService.dashboardData.pipe(takeUntil(this._unsubscribeAll)).subscribe((dashboardData) => {
    //   if (dashboardData != null) {
    //     this.surveyDashboard = dashboardData;
    //   }
    // });

    this.dashboardService.orgSurveyList.pipe(takeUntil(this._unsubscribeAll)).subscribe((surveys) => {
      if (surveys != null) {
        this.surveyList = surveys;
      }
    });

    this.dashboardService.currentSurvey.pipe(takeUntil(this._unsubscribeAll)).subscribe((survey) => {
      if (survey != null) {
        let orgId = this.userData.organisation_id;
        this.getDashboardDataForSurveyFromServer(survey.surveyId, orgId);
        _appUtil.writeCurrentSurveyId(survey.surveyId);

        // if (_appUtil.getCurrentSurveyId() != survey.surveyId || this.surveyDashboard == null) {
        // }
      }
    });

    if (localStorage.getItem("currentLang") == null) {
      this.arabicLang = "en";
      localStorage.setItem("currentLang", this.arabicLang);
    } else {
      this.arabicLang = localStorage.getItem("currentLang");
    }
  }

  getDashboardDataForSurveyFromServer(surveyId: string, orgId: string) {
    this._appService.getSurveyDashboardForSurvey(surveyId, orgId).subscribe(
      (response: any) => {
        if (response.status == APIResponse.Success) {
          this.surveyDashboard = SurveyDashboard.getSurveyDashboard(response.survey);
          this.surveyResponse = this.surveyDashboard.surveyResponses;
          this.getGlobalSatistics();
          this.dashboardService.dashboardSubject.next(this.surveyDashboard);
          localStorage.setItem("dashboardSubject", JSON.stringify(this.surveyDashboard));
        } else {
          this._appUtil.showAlertMessage(AlertType.Error, "commonNetworkErrorMessage");
        }
      },
      (err) => {
        this._appUtil.showAlertMessage(AlertType.Error, "commonNetworkErrorMessage");
      }
    );
  }

  
  getFilterData() {
    this._appService.getOrgFilterList().subscribe(
      (response: any) => {
        if (response.status == APIResponse.Success) {
          // console.log(response);
          this.fiterData = FilterData.getFilterData(response.orgFilters);
          this.dashboardService.filterDataSubject.next(this.fiterData);
          localStorage.setItem("orgFilterData", JSON.stringify(this.fiterData));
        } else {
          this._appUtil.showAlertMessage(AlertType.Error, "commonNetworkErrorMessage");
        }
      },
      (err) => {
        this._appUtil.showAlertMessage(AlertType.Error, "commonNetworkErrorMessage");
      }
    );
  }

  sampleData = [
    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "1",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "3",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "2",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "4",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "3",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "5",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "4",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "5",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "5",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "4",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "6",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "3",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "7",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "2",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "8",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "9",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "3",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "10",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "11",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "5",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "12",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "4",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "13",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "14",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "2",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "15",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "4",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "16",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "3",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "17",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "18",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "2",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "19",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "3",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "20",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
    {
      answer: "",
      answerId: "5",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "21",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "5",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "22",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "23",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "3",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "24",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "4",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "25",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "5",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "26",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "3",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "27",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "2",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "28",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "29",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "5",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "30",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "1",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "31",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },

    {
      answer: "",
      answerId: "4",
      experienceId: 1,
      experienceLevel: 6,
      genderId: 1,
      icpiller_id: 0,
      questionId: "32",
      questionType: 3,
      regionId: 2,
      roleCategory: 1,
      roleId: 2,
      sectorId: 3,
      userId: 11,
      yearsOfExp: null,
    },
  ];

  getGlobalSatistics() {
    // Calculate Maturity
    const maturityResponse = this.surveyResponse.filter((survey) => survey.questionId == 20 || survey.questionId == 21 || survey.questionId == 22);
    let maturityPossitiveResponse = maturityResponse.filter((survey) => survey.answerId == 4 || survey.answerId == 5);
    this.graphDashboard.overallMaturity = (maturityPossitiveResponse.length / maturityResponse.length) * 100;

    // Calculate Awareness
    const awarenessResponse = this.surveyResponse.filter(
      (survey) => survey.questionId == 6 || survey.questionId == 7 || survey.questionId == 8 || survey.questionId == 9
    );
    let awarenessPositiveResponse = awarenessResponse.filter((survey) => survey.answerId == 4 || survey.answerId == 5);
    this.graphDashboard.overallAwareness = (awarenessPositiveResponse.length / awarenessResponse.length) * 100;

    // Calculate Engagement
    const engagementResponse = this.surveyResponse.filter(
      (survey) => survey.questionId == 13 || survey.questionId == 14 || survey.questionId == 15 || survey.questionId == 16
    );
    let engagementPositiveResponse = engagementResponse.filter((survey) => survey.answerId == 4 || survey.answerId == 5);
    this.graphDashboard.overallEnagement = (engagementPositiveResponse.length / engagementResponse.length) * 100;

    // Calculate Relations
    const relationsResponse = this.surveyResponse.filter((survey) => survey.questionId == 10 || survey.questionId == 11 || survey.questionId == 12);

    let relationPositiveResponse = relationsResponse.filter((survey) => survey.answerId == 4 || survey.answerId == 5);
    this.graphDashboard.overallRelation = (relationPositiveResponse.length / relationsResponse.length) * 100;

    // Calculate Appriciation
    const appricationResponse = this.surveyResponse.filter((survey) => survey.questionId == 17 || survey.questionId == 18 || survey.questionId == 19);
    let appriciationPositiveResponse = appricationResponse.filter((survey) => survey.answerId == 4 || survey.answerId == 5);
    this.graphDashboard.overallAppriciation = (appriciationPositiveResponse.length / appricationResponse.length) * 100;

    this.orgOverallStats.orgOverallMean =
      (this.graphDashboard.overallMaturity +
        this.graphDashboard.overallAppriciation +
        this.graphDashboard.overallAwareness +
        this.graphDashboard.overallEnagement +
        this.graphDashboard.overallRelation) /
      5;

    this.orgOverallStats.overallMaturity = this.graphDashboard.overallMaturity;
    let maturityHistory = this.surveyDashboard.globalStats.find((tempScore) => tempScore.icPillerId == ICPillerId.Maturity);
    this.orgOverallStats.matutiryGlobalDiff = this.orgOverallStats.overallMaturity - maturityHistory.globalScorePercent;
    this.orgOverallStats.matutiryLastYearDiff = this.orgOverallStats.overallMaturity - maturityHistory.lastYearScorePercent;

    this.orgOverallStats.overallAwareness = this.graphDashboard.overallAwareness;
    let awarenessHistory = this.surveyDashboard.globalStats.find((tempScore) => tempScore.icPillerId == ICPillerId.Awareness);
    this.orgOverallStats.awarenessGlobalDiff = this.orgOverallStats.overallAwareness - awarenessHistory.globalScorePercent;
    this.orgOverallStats.awarenessLastYearDiff = this.orgOverallStats.overallAwareness - awarenessHistory.lastYearScorePercent;

    this.orgOverallStats.overallEnagement = this.graphDashboard.overallEnagement;
    let engagementHistory = this.surveyDashboard.globalStats.find((tempScore) => tempScore.icPillerId == ICPillerId.Engagement);
    this.orgOverallStats.engagementGlobalDiff = this.orgOverallStats.overallEnagement - engagementHistory.globalScorePercent;
    this.orgOverallStats.engagementLastYearDiff = this.orgOverallStats.overallEnagement - engagementHistory.lastYearScorePercent;

    this.orgOverallStats.overallRelation = this.graphDashboard.overallRelation;
    let relationHistory = this.surveyDashboard.globalStats.find((tempScore) => tempScore.icPillerId == ICPillerId.Relations);
    this.orgOverallStats.relationGlobalDiff = this.orgOverallStats.overallRelation - relationHistory.globalScorePercent;
    this.orgOverallStats.relationLastYearDiff = this.orgOverallStats.overallRelation - relationHistory.lastYearScorePercent;

    this.orgOverallStats.overallAppriciation = this.graphDashboard.overallAppriciation;
    let appriciationHistory = this.surveyDashboard.globalStats.find((tempScore) => tempScore.icPillerId == ICPillerId.Appriciation);
    this.orgOverallStats.appriciationGlobalDiff = this.orgOverallStats.overallAppriciation - appriciationHistory.globalScorePercent;
    this.orgOverallStats.appriciationLastYearDiff = this.orgOverallStats.overallAppriciation - appriciationHistory.lastYearScorePercent;

    let surveyQuestionIds = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    let surveyResponse = this.surveyResponse.filter((response) => surveyQuestionIds.some((questionId) => response.questionId == questionId));
    let surveyHighResponse = this.getHighRatingResponse(surveyResponse);
    let finalScore = ((surveyHighResponse.length / surveyResponse.length) * 100).toFixed(2);
    this.orgOverallStats.overallOrganisationScore = +finalScore;

    var tempGlobalScore = 0;
    var tempLastYearScore = 0;
    this.surveyDashboard.globalStats.forEach((globalScore) => {
      tempGlobalScore += globalScore.globalScorePercent;
      tempLastYearScore += globalScore.lastYearScorePercent;
    });

    this.orgOverallStats.overallGlobalScore = tempGlobalScore / this.surveyDashboard.globalStats.length;
    this.orgOverallStats.overallLastYearScore = tempLastYearScore / this.surveyDashboard.globalStats.length;
    this.orgOverallStats.overallGlobalScoreDiff = this.orgOverallStats.overallOrganisationScore - this.orgOverallStats.overallGlobalScore;
    this.orgOverallStats.overallLastYearScoreDiff = this.orgOverallStats.overallOrganisationScore - this.orgOverallStats.overallLastYearScore;

    // ******************* Remove this Once global score is Enabled ***********************
    this.orgOverallStats.matutiryGlobalDiff = 0.0;
    this.orgOverallStats.matutiryLastYearDiff = 0.0;
    this.orgOverallStats.awarenessGlobalDiff = 0.0;
    this.orgOverallStats.awarenessLastYearDiff = 0.0;
    this.orgOverallStats.engagementGlobalDiff = 0.0;
    this.orgOverallStats.engagementLastYearDiff = 0.0;
    this.orgOverallStats.relationGlobalDiff = 0.0;
    this.orgOverallStats.relationLastYearDiff = 0.0;
    this.orgOverallStats.appriciationGlobalDiff = 0.0;
    this.orgOverallStats.appriciationLastYearDiff = 0.0;
    this.orgOverallStats.overallGlobalScoreDiff = 0.0;
    this.orgOverallStats.overallLastYearScoreDiff = 0.0;
    // ******************* Remove this once global score is enabled ***********************/

    this.dashboardService.orgOverallStatsSubject.next(this.orgOverallStats);
    localStorage.setItem("orgOverallStatsSubject", JSON.stringify(this.orgOverallStats));
  }

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
    { text: "Arabic", flag: "assets/images/flags/russia.jpg", lang: "ar" },
  ];

  getHighRatingResponse(surveys) {
    return surveys.filter((survey) => survey.answerId == 4 || survey.answerId == 5);
  }

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    var SessionDetails = JSON.parse(localStorage.getItem('SessionDetails'));
      this.updateTheme();
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }
  updateTheme(){
    const color = JSON.parse(localStorage.getItem('SessionDetails'));
     if (color.orgPrimaryColour !== null) {   
      document.documentElement.style.setProperty('--primary-color', color.orgPrimaryColour);
    }
    if (color.orgSecondaryColour !== null) {   
      document.documentElement.style.setProperty('--secondary-color', color.orgSecondaryColour);
    }
    if (color.orgPrimaryColour == null) {   
      document.documentElement.style.setProperty('--primary-color', '#2d4099');
    }
    if (color.orgSecondaryColour == null) {   
      document.documentElement.style.setProperty('--secondary-color', '#eff3fc');
    }
   }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
  changePassword(){
    
  }
  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === "firebase") {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(["/account/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  changeLanguage(lang: string) {   
    this.languageService.AClicked(lang);    
    let htmlTag = this.document.getElementsByTagName("html")[0] as HTMLElement;
    localStorage.setItem("currentLang", lang);
    // window.location.reload();
    this.arabicLang = localStorage.getItem("currentLang");
    this.translateService.use(lang);
    htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
    this.languageService.setLanguage(lang);
    // let translatedText = this.translateService.instant("Attraction");
    // let translatedSearchDoctor = this.translateService.instant("SearchLocation");
    // let translatedSearchLocation = this.translateService.instant("SearchDo");
    // this.AppScreen.changeLanguageAll(lang);
    // this.AppScreen.rerender();

    // this.DashboardPage.setLanguage('ar');
    this.updateStyles();
  }
  updateStyles(): void {
    this.cdr.detectChanges();
  }
  darkMode(mode) {
    if (mode == "dark") {
      $("body").addClass("dark-mode");
      this.darkmodeVar = mode;
    }
    if (mode == "light") {
      $("body").removeClass("dark-mode");
      this.darkmodeVar = mode;
    }
  }
}
