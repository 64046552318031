import { environment } from "../../environments/environment";

export * from "./app-enum";
export * from "./app-literals";

export class AppConstants {
  public static get loginURL(): string {
    return `${environment.domainName}${environment.loginURL}`;
  }

  public static get surveyDashboardURL(): string {
    return `${environment.domainName}${environment.surveyDashboardURL}`;
  }

  public static get orgAdminDashboardURL(): string {
    return `${environment.domainName}${environment.orgAdminDashboardURL}`;
  }

  public static get getOrganisationListURL(): string {
    return `${environment.domainName}${environment.getOrganisationListURL}`;
  }
  public static get getICMStoreProductLandingContentURL(): string {
    return `${environment.domainName}${environment.getICMStoreProductLandingContentURL}`;
  }
  public static get filterProductURL(): string {
    return `${environment.domainName}${environment.filterProductURL}`;
  }
  public static get createNewSurveyURL(): string {
    return `${environment.domainName}${environment.createNewSurveyURL}`;
  }
  public static get updateSurveyEmailTemplateContentURL(): string {
    return `${environment.domainName}${environment.updateSurveyEmailTemplateContentURL}`;
  }
  public static get cloneExistingSurveyURL(): string {
    return `${environment.domainName}${environment.cloneExistingSurveyURL}`;
  }

  public static get getQuestionListURL(): string {
    return `${environment.domainName}${environment.getQuestionListURL}`;
  }

  public static get getOrgSurveyDashboardDetailsURL(): string {
    return `${environment.domainName}${environment.getOrgSurveyDashboardDetailsURL}`;
  }
  public static get getOrgNonOHBISurveyDashboardDetailsURL(): string {
    return `${environment.domainName}${environment.getOrgNonOHBISurveyDashboardDetailsURL}`;
  }

  public static get getInformativequestionURL(): string {
    return `${environment.domainName}${environment.getInformativequestionURL}`;
  }

  public static get getSurveyListURL(): string {
    return `${environment.domainName}${environment.getSurveyListURL}`;
  }

  public static get getSectorListURL(): string {
    return `${environment.domainName}${environment.getSectorListURL}`;
  }

  public static get getEmpRoleListURL(): string {
    return `${environment.domainName}${environment.getEmpRoleListURL}`;
  }

  public static get getRegionListURL(): string {
    return `${environment.domainName}${environment.getRegionListURL}`;
  }

  public static get addOrUpdateSectorURL(): string {
    return `${environment.domainName}${environment.addOrUpdateSectorURL}`;
  }

  public static get deleteSectorURL(): string {
    return `${environment.domainName}${environment.deleteSectorURL}`;
  }

  public static get createNewRoleURL(): string {
    return `${environment.domainName}${environment.createNewRoleURL}`;
  }

  public static get deleteRoleURL(): string {
    return `${environment.domainName}${environment.deleteRoleURL}`;
  }

  public static get createNewRegionURL(): string {
    return `${environment.domainName}${environment.addOrUpdateRegionURL}`;
  }

  public static get deleteRegionURL(): string {
    return `${environment.domainName}${environment.deleteRegionURL}`;
  }

  public static get createNewEmployeeURL(): string {
    return `${environment.domainName}${environment.createNewEmployeeURL}`;
  }

  public static get getOrgDashboardURL(): string {
    return `${environment.domainName}${environment.getOrgDashboardURL}`;
  }

  public static get getOrgDashboardDetailsURL(): string {
    return `${environment.domainName}${environment.getOrgDashboardDetailsURL}`;
  }

  public static get getOrgEmployeeListURL(): string {
    return `${environment.domainName}${environment.getOrgEmployeeListURL}`;
  }

  public static get createOrgEmployeeTypeURL(): string {
    return `${environment.domainName}${environment.createOrgEmployeeTypeURL}`;
  }

  public static get getOrgEmployeeTypeListURL(): string {
    return `${environment.domainName}${environment.getOrgEmployeeTypeListURL}`;
  }

  public static get getOrgEmployeeDetailURL(): string {
    return `${environment.domainName}${environment.getOrgEmployeeDetailURL}`;
  }

  public static get updateSurveyEmailTemplateURL(): string {
    return `${environment.domainName}${environment.updateSurveyEmailTemplateURL}`;
  }
  public static get activateOrInactivateOrgRegionURL(): string {
    return `${environment.domainName}${environment.activateOrInactivateOrgRegionURL}`;
  }
  public static get activateOrInactivateOrgSectorURL(): string {
    return `${environment.domainName}${environment.activateOrInactivateOrgSectorURL}`;
  }
  public static get activateOrInactivateOrgEmployeeRoleURL(): string {
    return `${environment.domainName}${environment.activateOrInactivateOrgEmployeeRoleURL}`;
  }
  public static get activateOrInactivateOrgEmployeeURL(): string {
    return `${environment.domainName}${environment.activateOrInactivateOrgEmployeeURL}`;
  }
  public static get activateOrInactivateOrgEmployeeTypeURL(): string {
    return `${environment.domainName}${environment.activateOrInactivateOrgEmployeeTypeURL}`;
  }

  public static get sendSurveyToEmployeesURL(): string {
    return `${environment.domainName}${environment.sendSurveyToEmployeesURL}`;
  }

  public static get sendTestSurvey(): string {
    return `${environment.domainName}${environment.sendTestSurvey}`;
  }

  public static get getOrgSurveyDetailsURL(): string {
    return `${environment.domainName}${environment.getOrgSurveyDetailsURL}`;
  }

  public static get sendSurveyToEmployeesBySearchURL(): string {
    return `${environment.domainName}${environment.sendSurveyToEmployeesBySearchURL}`;
  }

  public static get searchOrgEmployeeURL(): string {
    return `${environment.domainName}${environment.searchOrgEmployeeURL}`;
  }

  //Task Planner

  public static get deleteCommentURL(): string {
    return `${environment.domainName}${environment.deleteCommentURL}`;
  }

  public static get deleteSubTaskURL(): string {
    return `${environment.domainName}${environment.deleteSubTaskURL}`;
  }

  public static get deleteTaskURL(): string {
    return `${environment.domainName}${environment.deleteTaskURL}`;
  }

  public static get deleteBucketURL(): string {
    return `${environment.domainName}${environment.deleteBucketURL}`;
  }

  public static get getSubTaskDetailsURL(): string {
    return `${environment.domainName}${environment.getSubTaskDetailsURL}`;
  }

  public static get getTaskDetailsURL(): string {
    return `${environment.domainName}${environment.getTaskDetailsURL}`;
  }

  public static get getBucketDetailsURL(): string {
    return `${environment.domainName}${environment.getBucketDetailsURL}`;
  }

  public static get getOrganisationTaskBucketListURL(): string {
    return `${environment.domainName}${environment.getOrganisationTaskBucketListURL}`;
  }
  public static get getOrganisationDashBoardURL(): string {
    return `${environment.domainName}${environment.getOrganisationDashBoardURL}`;
  }

  public static get getBucketTaskListURL(): string {
    return `${environment.domainName}${environment.getBucketTaskListURL}`;
  }

  public static get addOrUpdateCommentTotTaskURL(): string {
    return `${environment.domainName}${environment.addOrUpdateCommentTotTaskURL}`;
  }

  public static get addOrUpdateSubTaskToTaskURL(): string {
    return `${environment.domainName}${environment.addOrUpdateSubTaskToTaskURL}`;
  }

  public static get addOrUpdateTaskToTaskBucketURL(): string {
    return `${environment.domainName}${environment.addOrUpdateTaskToTaskBucketURL}`;
  }

  public static get addOrUpdateUsersToTaskBucketURL(): string {
    return `${environment.domainName}${environment.addOrUpdateUsersToTaskBucketURL}`;
  }

  public static get addOrUpdateBucketURL(): string {
    return `${environment.domainName}${environment.addOrUpdateTaskBucketURL}`;
  }

  public static get addTaskAttachmentURL(): string {
    return `${environment.domainName}${environment.addTaskAttachmentURL}`;
  }

  public static get getAttractionSurveyDashbordURL(): string {
    return `${environment.domainName}${environment.getAttractionSurveyDashbordURL}`;
  }

  public static get sendNonOHBISurveyReminderURL(): string {
    return `${environment.domainName}${environment.sendNonOHBISurveyReminderURL}`;
  }
  public static get updateTotalOrderStatusURL(): string {
    return `${environment.domainName}${environment.updateTotalOrderStatusURL}`;
  }
  public static get deleteOrgSurveyURL(): string {
    return `${environment.domainName}${environment.deleteOrgSurveyURL}`;
  }
  public static get getOnboarding1DashbordURL(): string {
    return `${environment.domainName}${environment.getOnboarding1DashbordURL}`;
  }

  public static get getOnboarding2DashbordURL(): string {
    return `${environment.domainName}${environment.getOnboarding2DashbordURL}`;
  }

  public static get getDevelopmentSurveyDashbordURL(): string {
    return `${environment.domainName}${environment.getDevelopmentSurveyDashbordURL}`;
  }

  public static get getRetentionSurveyDashbordURL(): string {
    return `${environment.domainName}${environment.getRetentionSurveyDashbordURL}`;
  }

  public static get getExitSurveyDashbordURL(): string {
    return `${environment.domainName}${environment.getExitSurveyDashbordURL}`;
  }

  public static get getNonOHBISurveyStatsURL(): string {
    return `${environment.domainName}${environment.getNonOHBISurveyStatsURL}`;
  }

  public static get getOrgNonOHBISurveyQuestionsURL(): string {
    return `${environment.domainName}${environment.getOrgNonOHBISurveyQuestionsURL}`;
  }

  public static get sendAttractionSurveyInviteURL(): string {
    return `${environment.domainName}${environment.sendAttractionSurveyInviteURL}`;
  }

  public static get createNonOHBISurveyURL(): string {
    return `${environment.domainName}${environment.createNonOHBISurveyURL}`;
  }

  public static get setAutoSurveyInviteURL(): string {
    return `${environment.domainName}${environment.setAutoSurveyInviteURL}`;
  }

  public static get sendNonOHBISurveyToEmployeesBySearchURL(): string {
    return `${environment.domainName}${environment.sendNonOHBISurveyToEmployeesBySearchURL}`;
  }

  public static get getRecentlyJoinedEmpListURL(): string {
    return `${environment.domainName}${environment.getRecentlyJoinedEmpListURL}`;
  }

  public static get sendOnboardSurveyInviteURL(): string {
    return `${environment.domainName}${environment.sendOnboardSurveyInviteURL}`;
  }

  public static get getOtherNonOHBISurveyListURL(): string {
    return `${environment.domainName}${environment.getOtherNonOHBISurveyListURL}`;
  }

  public static get sendNonOHBISurveyToEmployeesURL(): string {
    return `${environment.domainName}${environment.sendNonOHBISurveyToEmployeesURL}`;
  }
  public static get sendSurveyThroughEmailURL(): string {
    return `${environment.domainName}${environment.sendSurveyThroughEmailURL}`;
  }

  public static get getOtherNonOHBISurveyDetailsURL(): string {
    return `${environment.domainName}${environment.getOtherNonOHBISurveyDetailsURL}`;
  }

  public static get getAutoSurveyInviteListURL(): string {
    return `${environment.domainName}${environment.getAutoSurveyInviteListURL}`;
  }

  public static get getNonOHBISurveyListURL(): string {
    return `${environment.domainName}${environment.getNonOHBISurveyListURL}`;
  }

  public static get getBucketUserListURL(): string {
    return `${environment.domainName}${environment.getBucketUserListURL}`;
  }

  public static get updateTaskStatusURL(): string {
    return `${environment.domainName}${environment.updateTaskStatusURL}`;
  }

  public static get updateSubTaskStatusURL(): string {
    return `${environment.domainName}${environment.updateSubTaskStatusURL}`;
  }

  public static get uploadSurveyImageURL(): string {
    return `${environment.domainName}${environment.uploadSurveyImageURL}`;
  }

  public static get updateEmpWebportalAccessURL(): string {
    return `${environment.domainName}${environment.updateEmpWebportalAccessURL}`;
  }

  public static get getCustomSurveyDashbordURL(): string {
    return `${environment.domainName}${environment.getCustomSurveyDashbordURL}`;
  }

  public static get createCustomSurveyURL(): string {
    return `${environment.domainName}${environment.createCustomSurveyURL}`;
  }
  public static get editCustomSurveyDetailURL(): string {
    return `${environment.domainName}${environment.editCustomSurveyDetailURL}`;
  }
  public static get getCustomSurveyDetailsURL(): string {
    return `${environment.domainName}${environment.getCustomSurveyDetailsURL}`;
  }

  public static get sendOrgSurveyRemainderURL(): string {
    return `${environment.domainName}${environment.sendOrgSurveyRemainderURL}`;
  }

  public static get getResalEGiftURL(): string {
    return "https://channels-api-stage.myresal.com/e-gift-products?filter%5Btype_id%5D=1&page=1&per_page=20&locale=en";
  }

  //ICM Store
  public static get createProductOrderForMultiEmpURL(): string {
    return `${environment.domainName}${environment.createProductOrderForMultiEmpURL}`;
  }
  public static get getOrgOrdersListURL(): string {
    return `${environment.domainName}${environment.getOrgOrdersListURL}`;
  }
  public static get getOrgOrderDetailsURL(): string {
    return `${environment.domainName}${environment.getOrgOrderDetailsURL}`;
  }
  public static get sendOrgGiftValueRequestURL(): string {
    return `${environment.domainName}${environment.sendOrgGiftValueRequestURL}`;
  }
  public static get updateOrgSettingsURL(): string {
    return `${environment.domainName}${environment.updateOrgSettingsURL}`;
  }
  public static get getOrgSettingsURL(): string {
    return `${environment.domainName}${environment.getOrgSettingsURL}`;
  }
  public static get fileUploadURL(): string {
    return `${environment.domainName}${environment.fileUploadURL}`;
  }
  public static get changePasswordOrgURL(): string {
    return `${environment.domainName}${environment.changePasswordOrgURL}`;
  }
  public static get getOrgGiftValueHistoryURL(): string {
    return `${environment.domainName}${environment.getOrgGiftValueHistoryURL}`;
  }
  public static get getSuggestionURL(): string {
    return `${environment.domainName}${environment.getSuggestionURL}`;
  }

  //Update Multi Employee Role Rank API
  public static get updateMultiEmpRoleRankURL(): string {
    return `${environment.domainName}${environment.updateMultiEmpRoleRankURL}`;
  }

  //Update Multi Employee Role Rank API
  public static get updateEmpSupervisorURL(): string {
    return `${environment.domainName}${environment.updateEmpSupervisorURL}`;
  }

  //Get DepartmentList
  public static get getDeptEmployeeListURL(): string {
    return `${environment.domainName}${environment.getDeptEmployeeListURL}`;
  }

  public static get getSurveyCoverageURL(): string {
    return `${environment.domainName}${environment.getSurveyCoverageURL}`;
  }

  public static get sendSurveyInviteURL(): string {
    return `${environment.domainName}${environment.sendSurveyInviteURL}`;
  }

  public static get get360SurveyInviteRecordListURL(): string {
    return `${environment.domainName}${environment.get360SurveyInviteRecordListURL}`;
  }

  public static get send360SurveyInviteToEmpURL(): string {
    return `${environment.domainName}${environment.send360SurveyInviteToEmpURL}`;
  }

  public static get get360SurveyListURL(): string {
    return `${environment.domainName}${environment.get360SurveyListURL}`;
  }

  public static get getrecalculateSurveyCoverageValueURL(): string {
    return `${environment.domainName}${environment.getrecalculateSurveyCoverageValueURL}`;
  }

  public static get get360DegreeOrgDashboardURL(): string {
    return `${environment.domainName}${environment.get360DegreeOrgDashboardURL}`;
  }
  public static get getOrgSurveyInviteEmailTemplateURL(): string {
    return `${environment.domainName}${environment.getOrgSurveyInviteEmailTemplateURL}`;
  }

  public static get getGoalPillerListURL(): string {
    return `${environment.domainName}${environment.getGoalPillerListURL}`;
  }
  public static get getGoalCategoryListURL(): string {
    return `${environment.domainName}${environment.getGoalCategoryListURL}`;
  }
  public static get getGoalSubCategoryListURL(): string {
    return `${environment.domainName}${environment.getGoalSubCategoryListURL}`;
  }
  public static get createGoalCategoryListURL(): string {
    return `${environment.domainName}${environment.createGoalCategoryListURL}`;
  }
  public static get createGoalPillerListURL(): string {
    return `${environment.domainName}${environment.createGoalPillerListURL}`;
  }

  public static get createGoalSubCategoryListURL(): string {
    return `${environment.domainName}${environment.createGoalSubCategoryListURL}`;
  }

  public static get activateOrDeactivateGoalCategoryURL(): string {
    return `${environment.domainName}${environment.activateOrDeactivateGoalCategoryURL}`;
  }
  public static get getGoalListURL(): string {
    return `${environment.domainName}${environment.getGoalListURL}`;
  }
  public static get createGoalListURL(): string {
    return `${environment.domainName}${environment.createGoalListURL}`;
  }
  public static get getOrgAnnualEvaluationListURL(): string {
    return `${environment.domainName}${environment.getOrgAnnualEvaluationListURL}`;
  }
  public static get addOrUpdateOrgAnnualEvaluationURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgAnnualEvaluationURL}`;
  }

  public static get validateAutoSurveyInviteURL(): string {
    return `${environment.domainName}${environment.validateAutoSurveyInviteURL}`;
  }

  //Org strategic goal

  public static get getOrgStrategicObjectivesListURL(): string {
    return `${environment.domainName}${environment.getOrgStrategicObjectivesListURL}`;
  }

  public static get deleteOrgStrategicGoalURL(): string {
    return `${environment.domainName}${environment.deleteOrgStrategicGoalURL}`;
  }

  public static get getOrgStrategicGoalListURL(): string {
    return `${environment.domainName}${environment.getOrgStrategicGoalListURL}`;
  }

  public static get addOrUpdateOrgStrategicObjectivesURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgStrategicObjectivesURL}`;
  }

  public static get getOrgStartegicPillerListURL(): string {
    return `${environment.domainName}${environment.getOrgStartegicPillerListURL}`;
  }

  public static get addOrUpdateOrgStrategicPillerURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgStrategicPillerURL}`;
  }

  public static get getOrgStrategicEvaluationListURL(): string {
    return `${environment.domainName}${environment.getOrgStrategicEvaluationListURL}`;
  }

  public static get addOrUpdateOrgStrategicEvaluationURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgStrategicEvaluationURL}`;
  }

  public static get getOrgStrategicEvaluationDetailURL(): string {
    return `${environment.domainName}${environment.getOrgStrategicEvaluationDetailURL}`;
  }

  public static get initiateStrategicEmpGoalURL(): string {
    return `${environment.domainName}${environment.initiateStrategicEmpGoalURL}`;
  }

  public static get activeOrInactiveOrgStrategicGoalURL(): string {
    return `${environment.domainName}${environment.activeOrInactiveOrgStrategicGoalURL}`;
  }

  public static get addOrUpdateOrgStrategicGoalURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgStrategicGoalURL}`;
  }

  public static get getOrgStrategicGoalDashboardURL(): string {
    return `${environment.domainName}${environment.getOrgStrategicGoalDashboardURL}`;
  }

  //Org strategic goal

  public static get getOrgPerformanceObjectivesListURL(): string {
    return `${environment.domainName}${environment.getOrgPerformanceObjectivesListURL}`;
  }

  public static get addOrUpdateOrgPerformanceObjectivesURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgPerformanceObjectivesURL}`;
  }

  public static get getOrgPerformancePillerListURL(): string {
    return `${environment.domainName}${environment.getOrgPerformancePillerListURL}`;
  }

  public static get addOrUpdateOrgPerformancePillerURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgPerformancePillerURL}`;
  }

  public static get getOrgPerformanceEvaluationListURL(): string {
    return `${environment.domainName}${environment.getOrgPerformanceEvaluationListURL}`;
  }

  public static get addOrUpdateOrgPerformanceEvaluationURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgPerformanceEvaluationURL}`;
  }

  public static get getOrgPerformanceEvaluationDetailURL(): string {
    return `${environment.domainName}${environment.getOrgPerformanceEvaluationDetailURL}`;
  }

  public static get activeOrInactiveOrgPerformanceGoalURL(): string {
    return `${environment.domainName}${environment.activeOrInactiveOrgPerformanceGoalURL}`;
  }

  public static get getOrgPerformanceGoalListURL(): string {
    return `${environment.domainName}${environment.getOrgPerformanceGoalListURL}`;
  }

  public static get addOrUpdateOrgPerformanceGoalURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgPerformanceGoalURL}`;
  }

  public static get deleteOrgPerformanceGoalURL(): string {
    return `${environment.domainName}${environment.deleteOrgPerformanceGoalURL}`;
  }

  public static get initiatePerformanceEmpGoalURL(): string {
    return `${environment.domainName}${environment.initiatePerformanceEmpGoalURL}`;
  }

  public static get getOrgPerformanceEvaluationResultsURL(): string {
    return `${environment.domainName}${environment.getOrgPerformanceEvaluationResultsURL}`;
  }

  public static get getOrgPerformanceEvaluationForMilestoneURL(): string {
    return `${environment.domainName}${environment.getOrgPerformanceEvaluationForMilestoneURL}`;
  }

  
  public static get getEmployeePerformanceForMilestoneURL(): string {
    return `${environment.domainName}${environment.getEmployeePerformanceForMilestoneURL}`;
  }
  
  public static get getOrgFilterListURL(): string {
    return `${environment.domainName}${environment.getOrgFilterListURL}`;
  }
  public static get addOrUpdateOrgEmpPerformanceMultyGoalURL(): string {
    return `${environment.domainName}${environment.addOrUpdateOrgEmpPerformanceMultyGoalURL}`;
  }

  public static get getEmpGroupGoalListURL(): string {
    return `${environment.domainName}${environment.getEmpGroupGoalListURL}`;
  }
  
  
}
