import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConstants } from "../../utils/app-constants";

import { User } from "../models/auth.models";
import { DashboardService } from "src/app/service/dashboard.service";

@Injectable({ providedIn: "root" })
export class AuthfakeauthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private dashboardService: DashboardService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem("currentUser")));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    let params = {
      username: email,
      password: password,
    };
    //   return this._httpClient.post(AppConstants.loginURL, params, this.httpOptions);

    return this.http.post<any>(AppConstants.loginURL, params).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        // if (user && user.token) {

        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user.userDetails));
          this.currentUserSubject.next(user.userDetails);
        }
        return user;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("DashboardData");
    localStorage.removeItem("SessionDetails");
    localStorage.removeItem("surveyListSubject");
    localStorage.removeItem("currentSurveySubject");
    localStorage.removeItem("orgOverallStatsSubject");
    this.currentUserSubject.next(null);
    this.dashboardService.dashboardSubject.next(null);
    this.dashboardService.authenticationSubject.next(null);
    this.dashboardService.surveyListSubject.next(null);
    this.dashboardService.currentSurveySubject.next(null);
    this.dashboardService.orgOverallStatsSubject.next(null);
  }
}
