import { EmpRole } from "./emp-role.model";
import { Region } from "./region.model";
import { Sector } from "./sector.model";
import { Employeetype } from "./employeetype.model";
import { EmpSector } from "./empSector.model";

export class FilterData {



  orgSectors: Sector[] = [];
  orgRegions: Region[] = [];
  orgRoles: EmpRole[] = [];
  orgEmployeeTypeList: Employeetype[] = [];
  orgEmpSector: EmpSector[] = [];

  static getFilterData(filterdata): FilterData {
    var filterData = new FilterData();


    filterData.orgSectors = Sector.getSectors(filterdata.orgSectors);
    filterData.orgRegions = Region.getRegions(filterdata.orgRegions);
    filterData.orgRoles = EmpRole.getEmpRoles(filterdata.orgEmpRoles);
    filterData.orgEmployeeTypeList = Employeetype.getEmployeeType(filterdata.orgEmployeeTypeList);
    filterData.orgEmpSector = EmpSector.getEmpSector(filterdata.orgEmpSectorResponse);
    return filterData;
  }
}
